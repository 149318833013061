import {fork, all} from "redux-saga/effects"
import {userSagas} from './user/userSagas'
import {bidSagas} from './bid/bidSagas'
import {endpointSagas} from './endpoint/endpoingSagas'
import {appSagas} from './app/appSagas'
import {carSagas} from './car/carSagas'
import {requisiteSagas} from './requisite/requisiteSagas'
import {sellerSagas} from './seller/sellerSagas'
import {registersSagas} from "./registers/registersSagas"
import {notificationsSagas} from "./notifications/notificationsSagas"
import {transportsSagas} from "./transports/transportsSagas"

const rootSaga = function* () {
  yield all([
    fork(appSagas),
    fork(userSagas),
    fork(endpointSagas),
    fork(bidSagas),
    fork(carSagas),
    fork(requisiteSagas),
    fork(sellerSagas),
    fork(registersSagas),
    fork(notificationsSagas),
    fork(transportsSagas),
  ]);
};

export default rootSaga;
