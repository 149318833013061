import {Navigate, useRoutes} from 'react-router-dom';
// config
import {PATH_AFTER_LOGIN} from 'src/config-global';
//
import {mainRoutes} from './main';

import {authRoutes} from './auth';
import {dashboardRoutes} from './dashboard';
import {registersRoutes} from './registers';
import {transportsRoutes} from './transports';
import {createBrowserHistory} from 'history';
import {usePermission} from 'src/hooks/use-permission';

// ----------------------------------------------------------------------

const history = createBrowserHistory()

const router = function Router() {
  const {isApprover, isCreator} = usePermission()

  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Transports routes
    ...transportsRoutes,

    // Registers routes
    ...((isCreator || isApprover) ? registersRoutes : []),

    // Main routes
    ...mainRoutes,

    // No match 404
    {path: '*', element: <Navigate to="/operations" replace />},
  ]);
}
export default router
export {router}
