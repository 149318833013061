import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  transports: {
    loading: false,
    data: null,
  },
}

const transportsSlice = createSlice({
  name: 'transports',
  initialState,
  reducers: {
    fetch: (state, action) => {

    },
  },
})

const transportsReducer = transportsSlice.reducer

export {transportsSlice, transportsReducer}
