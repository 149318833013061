import {put, takeLatest} from "redux-saga/effects"
import {transportsSlice} from './transportsSlice'
import {network} from '../../utils/network'

function* fetch({payload}) {
  yield put(transportsSlice.actions.setLoading({type: 'transports', loading: true}))

  try {
    const response = yield network.axios({
      method: 'GET',
      url: '/transports/',
      params: {}
    })

  } catch (e) {
    console.log('e', e)
  }
  yield put(transportsSlice.actions.setLoading({type: 'transports', loading: false}))
}

export function* transportsSagas() {
  yield takeLatest(transportsSlice.actions.fetch.type, fetch)
}
